<template>
  <div>
    <b-row>
      <b-col md="12">
        <a
          @click="$router.back()"
          href="#"
          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Soal Ujian
              </span>
              <b-skeleton width="100%" v-if="isLoading"></b-skeleton>
              <span
                class="text-muted mt-3 font-weight-bold font-size-sm"
                v-if="!isLoading && currentSoalList[0]"
                >{{ currentSoalList[0].ujian.nama }}</span
              >
            </h3>
            <div class="card-toolbar">
              <!-- <span
                @click="modalBankSoal = true"
                class="btn btn-warning font-weight-bolder font-size-sm mr-2"
              >
                Ambil dari Bank Soal
              </span>
              <router-link
                :to="`/mapel/${idKurikulum}/ujian/${idUjian}/soal/create`"
                href="#"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Tambah Soal Baru</router-link
              > -->
            </div>
          </div>
          <div class="card-body pt-4">
            <!--            <b-row class="justify-content-between align-items-center mb-4">-->
            <!--              <b-col cols="auto">-->
            <!--                <a-->
            <!--                  href="#"-->
            <!--                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Daftar Soal Ujian</a>-->
            <!--              </b-col>-->
            <!--              <b-col cols="auto">-->
            <!--                <a href="#" class="btn btn-success font-weight-bolder font-size-sm">-->
            <!--    &lt;!&ndash;              <span class="svg-icon svg-icon-md svg-icon-white">&ndash;&gt;-->
            <!--    &lt;!&ndash;                &lt;!&ndash;begin::Svg Icon | path:assets/media/svg/icons/Communication/Add-user.svg&ndash;&gt;&ndash;&gt;-->
            <!--    &lt;!&ndash;                <inline-svg src="media/svg/icons/Communication/Add-user.svg" />&ndash;&gt;-->
            <!--    &lt;!&ndash;                &lt;!&ndash;end::Svg Icon&ndash;&gt; </span>&ndash;&gt;-->
            <!--                  Tambah Soal Baru</a-->
            <!--                >-->
            <!--              </b-col>-->
            <!--            </b-row>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              :items="dataListQuestion"
              :fields="fields"
              :busy="isLoading"
              @filtered="onFiltered"
              show-empty
              responsive
            >
              <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
              <template #empty="scope">
                <h6 class="text-center">Tidak ada soal ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada soal ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Soal:
                </div>
                <div v-html="row.item.question"></div>
              </template>
              <template #cell(soal)="data">
                <div
                  v-html="data.item.question"
                  style="
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  "
                ></div>
              </template>
              <template #cell(kode_soal)="data">
                {{ data.item.kode_soal }}
              </template>
              <template #cell(tipe)="data">
                <span
                  class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                >
                  {{ data.item.type == "bool" ? "true/false" : data.item.type }}
                </span>
              </template>
              <template #cell(bobot)="data">
                <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
                <!--            {{ topik }}-->
                <!--          </b-badge>-->

                <template v-if="currentSoalList.exam_type != 'ICES'">
                  <span
                    class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                  >
                    {{
                      data.item.answer
                        .filter((anItem) => {
                          return anItem.isCorrect == true;
                        })
                        .reduce((n, { point }) => n + point, 0)
                    }}
                    pts
                  </span>
                </template>
                <template v-else>
                  <div v-for="el in data.item.answer" :key="el._id">
                    <span
                      class="label label-lg label-inline font-weight-bold py-4 bg-info text-white mb-2 w-100 text-start"
                    >
                      {{ el.answer }} : {{ el.point }} pts
                    </span>
                    <br />
                  </div>
                </template>
              </template>
              <template #cell(topik)="data">
                <!--                <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
                <!--                  {{ topik }}-->
                <!--                </b-badge>-->
                <span
                  class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                  v-for="topik in data.item.topic"
                  :key="topik"
                >
                  {{ topik }}
                </span>
              </template>
              <template #cell(action)="data">
                <span
                  @click="data.toggleDetails"
                  class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                >
                  <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                  <i
                    :class="`ki ki-bold-arrow-${
                      data.detailsShowing ? 'up' : 'down mt-1'
                    } icon-sm`"
                  ></i>
                </span>
                <span
                  v-if="data.item.tipe != 'essay'"
                  @click="viewJawaban(data.item)"
                  class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
                  >Daftar Jawaban</span
                >
                <!--                <span-->
                <!--                    class="btn btn-icon btn-info btn-hover-info shadow-sm mr-1"-->
                <!--                    v-b-tooltip.hover.right="'Lihat Soal Lengkap'"-->
                <!--                    @click="data.toggleDetails">-->
                <!--&lt;!&ndash;                  {{ row.detailsShowing ? 'Hide' : 'Show'}} Details&ndash;&gt;-->
                <!--                  <i :class="`ki ki-bold-arrow-${data.detailsShowing ?'up':'down'} icon-sm`"></i>-->
                <!--                </span>-->
                <!--                <span-->
                <!--                      @click="viewJawaban(data.item)"-->
                <!--                      class="btn btn-icon btn-primary btn-hover-primary shadow-sm mr-1"-->
                <!--                      v-b-tooltip.hover.right="'Lihat Pilihan Jawaban'"-->
                <!--                  >-->
                <!--                    <i class="ki ki-bold-arrow-next icon-sm"></i>-->
                <!--                </span>-->
                <!-- <b-dropdown
                  variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <router-link
                        class="navi-link"
                        :to="`/mapel/${idKurikulum}/ujian/${idUjian}/soal/${data.item.id}/edit`"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-edit"></i>
                        </span>
                        <span class="navi-text">Edit Soal</span>
                      </router-link>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <span
                        class="navi-link cursor-pointer"
                        @click="deleteSoal(data.item)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-trash"></i>
                        </span>
                        <span class="navi-text">Hapus Soal</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                </b-dropdown> -->
                <!--                <router-link-->
                <!--                    :to="`/mapel/${idKurikulum}/ujian/${idUjian}/soal/${data.item.id}/edit`"-->
                <!--                    class="btn btn-icon btn-warning btn-hover-warning shadow-sm mr-1"-->
                <!--                    v-b-tooltip.hover.right="'Edit soal'"-->
                <!--                >-->
                <!--                  <i class="mdi mdi-pencil"></i>-->
                <!--                </router-link>-->
                <!--                <span-->
                <!--                    @click="deleteSoal(data.item)"-->
                <!--                    class="btn btn-icon btn-danger btn-hover-danger shadow-sm mr-1"-->
                <!--                    v-b-tooltip.hover.right="'Hapus soal'"-->
                <!--                >-->
                <!--                    <i class="mdi mdi-trash-can"></i>-->
                <!--                  </span>-->
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
        <!--        <b-card header-tag="header" footer-tag="footer">-->
        <!--          <template v-slot:header>-->
        <!--            <b-row>-->
        <!--              <b-col>-->
        <!--                <h6 class="mb-0">Daftar Soal</h6>-->
        <!--                <b-skeleton width="25%" v-if="isLoading"></b-skeleton>-->
        <!--                <span class="mb-0" v-if="!isLoading && currentSoalList[0]">{{ currentSoalList[0].ujian.nama }}</span>-->
        <!--              </b-col>-->
        <!--              <b-col cols="auto">-->
        <!--                <b-button pill variant="warning" class="mr-1" @click="$router.back()">Kembali</b-button>-->
        <!--                <b-button pill variant="primary" tag="router-link" :to="`/mapel/${idKurikulum}/ujian/${idUjian}/soal/create`">Tambah</b-button>-->
        <!--              </b-col>-->
        <!--            </b-row>-->
        <!--          </template>-->

        <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        <!--        </b-card>-->
        <b-modal
          v-model="modalBankSoal"
          centered
          scrollable
          title="Pilih dari Bank Soal"
          size="lg"
          @ok="getFromBankSoal"
        >
          <b-row class="justify-content-between">
            <b-col cols="auto">
              <b-form-group>
                <b-form-select
                  id="per-page-select"
                  v-model="perPageBank"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="auto">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filterBank"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filterBank" @click="filterBank = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
          <b-table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
            :filter="filterBank"
            :current-page="currentPageBank"
            :per-page="perPageBank"
            :items="bankSoal"
            :fields="fieldsBank"
            :busy="isLoading"
            @filtered="onFilteredBank"
            show-empty
          >
            <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
            <template #empty="scope">
              <h6 class="text-center">Tidak ada soal ditemukan</h6>
            </template>
            <template #emptyfiltered="scope">
              <h6 class="text-center">Tidak ada soal ditemukan</h6>
            </template>
            <template #cell(select)="data">
              <label class="checkbox checkbox-lg checkbox-single">
                <input
                  type="checkbox"
                  :value="data.item.id"
                  v-model="data.item.checked"
                  @input="onChangeRow"
                />
                <span></span>
              </label>
            </template>
            <template #head(select)="data">
              <label class="checkbox checkbox-lg checkbox-single">
                <input
                  type="checkbox"
                  v-model="selectAll"
                  @input="onChangeAll"
                />
                <span></span>
              </label>
            </template>
            <template #row-details="row">
              <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                Soal:
              </div>
              <div v-html="row.item.soal"></div>
              <div
                class="text-dark-75 font-weight-bolder mt-2 mb-1 font-size-lg"
              >
                Pilihan Jawaban:
              </div>
              <div class="jawaban">
                <div
                  :class="`card card-custom card-stretch border ${
                    jawaban.is_jawaban
                      ? 'bg-light-primary text-primary border-primary'
                      : ''
                  }`"
                  v-for="(jawaban, i) in row.item.jawabans"
                  :key="`${row.item.id}${i}`"
                >
                  <div
                    class="card-body d-flex flex-row p-4 justify-content-between align-items-center"
                  >
                    <!--                <b-form-checkbox disabled :name="`jawaban${selectedSoal.id}`" v-model="jawaban.is_jawaban" size="lg" class="align-items-start cursor-pointer" v-if="selectedSoal.tipe=='multiple'"></b-form-checkbox>-->
                    <!--                <b-form-radio :name="`jawaban${selectedSoal.id}`" :value="jawaban.id_jawaban" v-model="selectedSoal.id_jawaban" size="lg" class="align-self-start cursor-pointer" v-else></b-form-radio>-->
                    <div v-html="jawaban.jawaban"></div>
                    <div v-if="jawaban.bobot && jawaban.bobot > 0">
                      {{ jawaban.bobot }} pts
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(index)="row">
              {{ perPage * (currentPage - 1) + (row.index + 1) }}
            </template>
            <template #cell(soal)="data">
              <div
                v-html="data.item.soal"
                style="
                  width: 100px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                "
              ></div>
            </template>
            <template #cell(kode_soal)="data">
              {{ data.item.kode_soal }}
            </template>
            <template #cell(tipe)="data">
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
              >
                {{ tipeSoal[data.item.tipe] }}
              </span>
            </template>
            <template #cell(bobot)="data">
              <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
              <!--            {{ topik }}-->
              <!--          </b-badge>-->
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
              >
                {{ data.item.bobot }} pts
              </span>
            </template>
            <template #cell(topik)="data">
              <!--          <b-badge class="mr-1" pill href="#" variant="info" v-for="topik in data.item.topik" :key="topik">-->
              <!--            {{ topik }}-->
              <!--          </b-badge>-->
              <span
                class="label label-lg label-inline font-weight-bold py-4 label-light-info mr-1"
                v-for="topik in data.item.topik"
                :key="topik"
              >
                {{ topik }}
              </span>
            </template>
            <template #cell(action)="data">
              <span
                @click="data.toggleDetails"
                class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
              >
                <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                <i
                  :class="`ki ki-bold-arrow-${
                    data.detailsShowing ? 'up' : 'down mt-1'
                  } icon-sm`"
                ></i>
              </span>
              <!--              <span-->
              <!--                  @click="pilihBankSoal(data.item)"-->
              <!--                  class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"-->
              <!--              >Pilih</span>-->
              <!--          <span-->
              <!--              class="btn btn-icon btn-info btn-hover-info shadow-sm mr-1"-->
              <!--              v-b-tooltip.hover.right="'Lihat Soal Lengkap'"-->
              <!--              @click="data.toggleDetails">-->
              <!--&lt;!&ndash;                  {{ row.detailsShowing ? 'Hide' : 'Show'}} Details&ndash;&gt;-->
              <!--            <i :class="`ki ki-bold-arrow-${data.detailsShowing ?'up':'down'} icon-sm`"></i>-->
              <!--          </span>-->
              <!--          <span-->
              <!--              @click="pilihBankSoal(data.item)"-->
              <!--              class="btn btn-icon btn-primary btn-hover-primary shadow-sm mr-1"-->
              <!--              v-b-tooltip.hover.right="'Pilih Bank Soal'"-->
              <!--          >-->
              <!--            <i class="ki ki-bold-check icon-sm"></i>-->
              <!--          </span>-->
            </template>
          </b-table>
          <b-pagination
            v-model="currentPageBank"
            :total-rows="totalRowsBank"
            :per-page="perPageBank"
            align="right"
            class="my-0"
          ></b-pagination>
        </b-modal>
        <b-modal
          v-model="modalJawaban"
          centered
          scrollable
          title="Pilihan Jawaban"
          hide-footer
        >
          <div class="jawaban">
            <div
              :class="`card card-custom card-stretch border ${
                jawaban.isCorrect
                  ? 'bg-light-primary text-primary border-primary'
                  : ''
              }`"
              v-for="(jawaban, i) in selectedSoal.answer"
              :key="`${selectedSoal.id}${i}`"
            >
              <div
                class="card-body d-flex flex-row p-4 justify-content-between align-items-center"
              >
                <!--                <b-form-checkbox disabled :name="`jawaban${selectedSoal.id}`" v-model="jawaban.is_jawaban" size="lg" class="align-items-start cursor-pointer" v-if="selectedSoal.tipe=='multiple'"></b-form-checkbox>-->
                <!--                <b-form-radio :name="`jawaban${selectedSoal.id}`" :value="jawaban.id_jawaban" v-model="selectedSoal.id_jawaban" size="lg" class="align-self-start cursor-pointer" v-else></b-form-radio>-->
                <div v-html="jawaban.answer"></div>
                <div v-if="jawaban.point && jawaban.point > 0">
                  {{ jawaban.point }} pts
                </div>
              </div>
            </div>
          </div>
          <!--          <b-input-group class="mb-2" v-for="jawaban in selectedSoal.jawabans" :key="jawaban.id">-->
          <!--            <b-input-group-prepend is-text>-->
          <!--              <label class="checkbox checkbox-lg checkbox-single">-->
          <!--                <input :key="`${i}${jawaban.is_jawaban}`" type="checkbox" v-model="jawaban.is_jawaban" disabled />-->
          <!--                <span></span>-->
          <!--              </label>-->
          <!--&lt;!&ndash;              <b-form-radio class="mr-n2" :options="jawaban" disabled :value="jawaban.id" value-field="id" :checked="selectedSoal.id_jawaban" name="pilihan_jawaban">&ndash;&gt;-->
          <!--&lt;!&ndash;                <span class="sr-only">Radio for following text input</span>&ndash;&gt;-->
          <!--&lt;!&ndash;              </b-form-radio>&ndash;&gt;-->
          <!--            </b-input-group-prepend>-->
          <!--            <quill-editor-->
          <!--                class="form-control m-0 p-0"-->
          <!--                ref="deskripsiQuill"-->
          <!--                v-model="jawaban.jawaban"-->
          <!--                :options="editorOption"-->
          <!--                :disabled='true'-->
          <!--            />-->
          <!--          </b-input-group>-->
        </b-modal>
      </b-col>
      <!--      <b-col md="4">-->
      <!--        <div class="card card-custom card-stretch">-->
      <!--          <div class="card-body pt-4">-->

      <!--          </div>-->
      <!--        </div>-->
      <!--      </b-col>-->
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  DELETE_SOAL,
  GET_FROM_BANK_SOAL,
  GET_SOAL,
} from "@/core/services/store/soal.module";
import { GET_BANK_SOAL } from "@/core/services/store/banksoal.module";
export default {
  name: "SoalList",
  data() {
    return {
      isLoading: false,
      modalJawaban: false,
      selectedSoal: {},
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      modalBankSoal: false,
      bankSoal: [],
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "question_code",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "bobot",
          label: "Bobot Soal",
          sortable: true,
        },
        // {
        //   key: 'soal',
        //   label: "Soal",
        //   sortable: true
        // },
        {
          key: "tipe",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topik",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      tipeSoal: {
        pg: "Pilihan Ganda",
        multiple: "Jawaban Ganda",
        truefalse: "True/False",
        essay: "Essay",
      },
      currentSoal: {},
      currentPageBank: 1,
      totalRowsBank: 0,
      perPageBank: 10,
      filterBank: null,
      selectAll: false,
      fieldsBank: [
        {
          key: "select",
          sortable: false,
        },
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_soal",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "bobot",
          label: "Bobot Soal",
          sortable: true,
        },
        // {
        //   key: 'soal',
        //   label: "Soal",
        //   sortable: true
        // },
        {
          key: "type",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topik",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Detail Ujian" }]);
    this.getData();
    this.getBankSoal();
  },
  mounted() {},
  computed: {
    ...mapState({
      errors: (state) => state.soal.errors,
    }),
    ...mapGetters(["currentSoalList"]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    dataListQuestion() {
      if (this.currentSoalList.question_pack_id === undefined) return [];
      return this.currentSoalList.question_pack_id.questions;
    },
  },
  methods: {
    getFromBankSoal() {
      this.$bvModal
        .msgBoxConfirm(
          "Apakah anda yakin ingin ambil data soal dari bank soal?",
          {
            title: "Konfirmasi",
            size: "sm",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "Ya",
            cancelTitle: "Tidak",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.isLoading = true;
            var _data = this.bankSoal
              .filter((item) => {
                return item.checked;
              })
              .map((item) => {
                return item.id;
              });
            this.$store
              .dispatch(GET_FROM_BANK_SOAL, { id: this.idUjian, ids: _data })
              .then((response) => {
                this.getData();
                this.$bvToast.toast("Berhasil ambil dari bank soal", {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Export Rekap Nilai`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        });
    },
    onChangeAll() {
      this.bankSoal.forEach((item, index) => {
        this.$set(this.bankSoal[index], "checked", !this.selectAll);
      });
    },
    onChangeRow(e) {
      if (!e.target.checked) {
        this.selectAll = false;
      } else {
        var allSelected = this.bankSoal.every((val) => {
          return val.checked;
        });
        if (allSelected) {
          this.selectAll = true;
        }
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredBank(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsBank = filteredItems.length;
      this.currentPageBank = 1;
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_SOAL, this.idUjian)
        .then(() => {
          this.isLoading = false;
          this.totalRows =
            this.currentSoalList.question_pack_id.questions.length;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat SOal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteSoal(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus soal ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_SOAL, { idUjian: this.idUjian, id: item.id })
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus soal`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Hapus Soal`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    viewJawaban(item) {
      this.selectedSoal = item;
      this.modalJawaban = true;
    },
    getBankSoal() {
      const param = new URLSearchParams();
      param.append("id_kurikulum", this.idKurikulum);
      this.$store
        .dispatch(GET_BANK_SOAL, param.toString())
        .then((response) => {
          this.bankSoal = response.data;
          this.totalRowsBank = this.bankSoal.length;
          // this.isLoading = false
        })
        .catch(() => {
          // this.isLoading = false
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style scoped></style>
